//滚动到指定位置
const scrollAnimation = (target: number) => {
  let timer: any
  let currentPosition = document.documentElement.scrollTop || document.body.scrollTop
  let speed = 30,
    isTop = true
  if (currentPosition - target < 0) {
    isTop = false
  }
  if (isTop) {
    timer = setInterval(function () {
      currentPosition -= speed
      if (currentPosition > target) {
        window.scrollTo(0, currentPosition)
      } else {
        window.scrollTo(0, target)
        clearInterval(timer)
      }
    }, 5)
  } else {
    timer = setInterval(function () {
      currentPosition += speed
      if (currentPosition < target) {
        window.scrollTo(0, currentPosition)
      } else {
        window.scrollTo(0, target)
        clearInterval(timer)
      }
    }, 5)
  }
}

// 时间转换
const formatDate = (date: string) => {
  const list: any[] = date.split(/\/|\-|\s/)

  if (list?.length > 0) {
    let monthEn = ['Jan.', 'Feb.', 'Mar.', 'Apr.', 'May.', 'Jun.', 'Jul.', 'Aug.', 'Sep.', 'Oct.', 'Nov.', 'Dec.']
    let YY = list[0]
    let DD = list[2]
    let m = Number(list[1])
    let MM = monthEn[m-1]
    return [YY, MM, DD]
  } else {
    return ''
  }
}

export { scrollAnimation, formatDate }
