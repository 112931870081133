import Vue from 'vue'
// import Antd from 'ant-design-vue/lib'
// import Message from 'ant-design-vue'
// Vue.prototype.$message = Message
// Vue.use(Antd)
import { ConfigProvider, Dropdown, Menu, Carousel } from 'ant-design-vue'
Vue.use(ConfigProvider)
Vue.use(Dropdown)
Vue.use(Menu)
Vue.use(Carousel)
