
import headerData from '@/db/header'
import {Component, Vue, Prop, Watch} from 'nuxt-property-decorator'

@Component
export default class extends Vue {
  @Prop({default: true}) isFromIndex!: boolean
  @Prop({default: false}) isFromRecruit!: boolean

  @Watch('menuVisible', {immediate: true}) handleVisible(val: boolean) {
    if (!val) {
      this.navList.forEach(e => {
        e.showList = false
      })
    }
  }

  navList: Array<any> = headerData.navList
  recruitList: Array<any> = headerData.recruitList
  headerLogo: Array<any> = headerData.headerLogo
  headerMenu: Array<any> = headerData.headerMenu
  menuVisible: boolean = false

  get showState() {
    return this.isFromRecruit && this.$store.state.isPullDown !== -1
  }

  handleMenuTap(v: any) {
    if (v.list && v.list.length) {
      v.showList = !v.showList
    } else {
      if (v?.target === '_blank') {
        window.open(v.url)
      } else {
        this.$router.push({path: v.url})
        this.menuVisible = false
      }
    }
  }
}
