export default {
  navList: [
    {
      id: 1,
      value: '首页',
      url: '/',
      list: [],
    },
    {
      id: 2,
      value: '简介',
      url: '/introduction',
      list: [],
    },
    {
      id: 3,
      value: '业务',
      url: '/business',
      showList: false,
      list: [],
    },
    {
      id: 4,
      value: '产品',
      url: 'javascript:;',
      showList: false,
      list: [
        {
          id: 42,
          value: '高端光学精密仪器',
          url: '/product/hardware',
        },
        {
          id: 43,
          value: '高级语音质量分析测试系统',
          url: '/product/voice',
        },
        {
          id: 41,
          value: 'SAAS及互联网产品',
          url: '/product/internet',
        },
      ],
    },
    {
      id: 5,
      value: '员工',
      url: '/employee',
      list: [],
    },
    {
      id: 6,
      value: '联系我们',
      url: '/connection',
      list: [],
    },
    {
      id: 7,
      value: '加入我们',
      url: '/recruit',
      list: [],
    },
  ],
  recruitList: [
    {
      id: 1,
      value: '首页',
      url: '/recruit',
    },
    {
      id: 2,
      value: '社会招聘',
      url: '/recruit/society',
    },
    {
      id: 3,
      value: '校园招聘',
      url: '/recruit/campus',
      target: '_blank'
    },
    {
      id: 4,
      value: '业务/产品',
      url: '/recruit/business',
    },
  ],
  headerLogo: [
    require('@/assets/images/common/header.svg'),
    require('@/assets/images/common/header-white.svg'),
  ],
  headerMenu: [
    require('@/assets/images/common/menu.svg'),
    require('@/assets/images/common/menu-white.svg'),
  ],
  internetPro: [
    {
      id: 1,
      value: '仪器买卖',
    },
    {
      id: 2,
      value: '木木西里实验室商城',
    },
    {
      id: 3,
      value: '仪器+',
    },
    {
      id: 4,
      value: '芝麻研',
    },
    {
      id: 5,
      value: 'SAAS管理系统',
    },
  ],
  hardwarePro: [
    {
      id: 6,
      topic: '木木西里®',
      list: [
        {
          id: 61,
          value: '固态样品制备',
        },
        {
          id: 62,
          value: '工业非活体显微镜',
        },
        {
          id: 63,
          value: '接触角测量仪',
        },
        {
          id: 64,
          value: '环境试验箱',
        },
      ],
    },
    {
      id: 7,
      topic: 'KathMatic®',
      list: [
        {
          id: 73,
          value: 'KC 激光光谱共聚焦显微镜',
        },
        {
          id: 72,
          value: 'KH 激光位移传感器',
        },
        {
          id: 71,
          value: 'KV 激光测振仪',
        },
      ],
    },
    {
      id: 8,
      topic: 'BIOption®',
      list: [
        {
          id: 81,
          value: '离心系列',
        },
        {
          id: 82,
          value: 'PCR系列',
        },
        {
          id: 83,
          value: '细胞培养系列',
        },
        {
          id: 84,
          value: '酶标系列',
        },
        {
          id: 85,
          value: '移液系列',
        },
      ],
    },
  ]
}
