import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _41d7628c = () => interopDefault(import('../pages/business/index.vue' /* webpackChunkName: "pages/business/index" */))
const _69752bea = () => interopDefault(import('../pages/connection/index.vue' /* webpackChunkName: "pages/connection/index" */))
const _66fc37fa = () => interopDefault(import('../pages/employee/index.vue' /* webpackChunkName: "pages/employee/index" */))
const _b75edc34 = () => interopDefault(import('../pages/introduction/index.vue' /* webpackChunkName: "pages/introduction/index" */))
const _6f0cd308 = () => interopDefault(import('../pages/recruit/index.vue' /* webpackChunkName: "pages/recruit/index" */))
const _2d5a1556 = () => interopDefault(import('../pages/connection/cooperation/index.vue' /* webpackChunkName: "pages/connection/cooperation/index" */))
const _37f3a6a2 = () => interopDefault(import('../pages/connection/dynamics/index.vue' /* webpackChunkName: "pages/connection/dynamics/index" */))
const _a42b0258 = () => interopDefault(import('../pages/product/hardware/index.vue' /* webpackChunkName: "pages/product/hardware/index" */))
const _9e617626 = () => interopDefault(import('../pages/product/internet/index.vue' /* webpackChunkName: "pages/product/internet/index" */))
const _0363779c = () => interopDefault(import('../pages/product/voice/index.vue' /* webpackChunkName: "pages/product/voice/index" */))
const _60e9d006 = () => interopDefault(import('../pages/recruit/business/index.vue' /* webpackChunkName: "pages/recruit/business/index" */))
const _54378adc = () => interopDefault(import('../pages/recruit/campus/index.vue' /* webpackChunkName: "pages/recruit/campus/index" */))
const _16a0252a = () => interopDefault(import('../pages/recruit/employee/index.vue' /* webpackChunkName: "pages/recruit/employee/index" */))
const _e684633e = () => interopDefault(import('../pages/recruit/society/index.vue' /* webpackChunkName: "pages/recruit/society/index" */))
const _28dc4ca9 = () => interopDefault(import('../pages/recruit/campus/future.vue' /* webpackChunkName: "pages/recruit/campus/future" */))
const _015a9b99 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/business",
    component: _41d7628c,
    name: "business"
  }, {
    path: "/connection",
    component: _69752bea,
    name: "connection"
  }, {
    path: "/employee",
    component: _66fc37fa,
    name: "employee"
  }, {
    path: "/introduction",
    component: _b75edc34,
    name: "introduction"
  }, {
    path: "/recruit",
    component: _6f0cd308,
    name: "recruit"
  }, {
    path: "/connection/cooperation",
    component: _2d5a1556,
    name: "connection-cooperation"
  }, {
    path: "/connection/dynamics",
    component: _37f3a6a2,
    name: "connection-dynamics"
  }, {
    path: "/product/hardware",
    component: _a42b0258,
    name: "product-hardware"
  }, {
    path: "/product/internet",
    component: _9e617626,
    name: "product-internet"
  }, {
    path: "/product/voice",
    component: _0363779c,
    name: "product-voice"
  }, {
    path: "/recruit/business",
    component: _60e9d006,
    name: "recruit-business"
  }, {
    path: "/recruit/campus",
    component: _54378adc,
    name: "recruit-campus"
  }, {
    path: "/recruit/employee",
    component: _16a0252a,
    name: "recruit-employee"
  }, {
    path: "/recruit/society",
    component: _e684633e,
    name: "recruit-society"
  }, {
    path: "/recruit/campus/future",
    component: _28dc4ca9,
    name: "recruit-campus-future"
  }, {
    path: "/",
    component: _015a9b99,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
