// user.js
// state为一个函数, 注意箭头函数写法
const state = () => ({
  isPullDown: 0,
  scrollTop: 0,
  activeTab: 0,
  baseFontSize: 100
})

// mutations为一个对象
const mutations = {
  SET_ISPULLDOWN(state: object, data: number) {
    state['isPullDown'] = data
  },
  SET_SCROLLTOP(state: object, data: number) {
    state['scrollTop'] = data
  },
  SET_ACTIVETAB(state: object, data: number) {
    state['activeTab'] = data
  },
  SET_FONTSIZE(state: object, data: number) {
    state['baseFontSize'] = data
  }
}
const actions = {

}
const getters = {

}
export default {
  namespace: true,	// 命名空间
  state,
  mutations,
  actions,
  getters
}