import { render, staticRenderFns } from "./PageHeader.vue?vue&type=template&id=05c0517f&scoped=true&"
import script from "./PageHeader.vue?vue&type=script&lang=ts&"
export * from "./PageHeader.vue?vue&type=script&lang=ts&"
import style0 from "./PageHeader.vue?vue&type=style&index=0&id=05c0517f&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05c0517f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderMenu: require('/root/workspace/mumuxili-mobile-front_KVKI/components/HeaderMenu/index.vue').default})
