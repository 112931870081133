
import { scrollAnimation } from '@/utils/utils'
// @ts-ignore
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { Component, Vue, Watch } from 'nuxt-property-decorator'
import PageFooter from './components/PageFooter.vue'
import PageHeader from './components/PageHeader.vue'
@Component({
  components: { PageFooter, PageHeader },
})
export default class extends Vue {
  locale: any = zhCN
  routePath: string = '/'
  isFromRecruit: boolean = false
  isFromIndex: boolean = true
  timer: any
  s: number = 0
  isBackShow: boolean = false
  backImg: any = require('@/assets/images/common/backtop-default.svg')

  @Watch('$route.path', { immediate: true }) changeRoutePath(val: string) {
    this.routePath = val
    this.isFromRecruit = this.routePath.indexOf('/recruit') != -1
    this.isFromIndex = val == '/'
  }
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
    let dom = document.documentElement || document.body
    let baseSize = Number(
      dom.style.fontSize.substring(0, dom.style.fontSize.length - 2)
    )
    this.$store.commit('SET_FONTSIZE', baseSize)
  }
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true)
  }
  handleScroll(e: any) {
    clearTimeout(this.timer)
    let st =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop
    let { isPullDown } = this.$store.state
    let flag = st == 0 ? 0 : st >= this.s ? 1 : st < this.s ? -1 : 0
    if (isPullDown != flag) {
      this.$store.commit('SET_ISPULLDOWN', flag)
    }
    this.$store.commit('SET_SCROLLTOP', st)
    this.timer = setTimeout(() => {
      this.s = st
    }, 500)
  }
  backToTop() {
    scrollAnimation(0)
  }
}
