export default {
  followList: [
    {
      type: require('@/assets/icon/platform/weibo.svg'),
      url: 'https://weibo.com/p/1006065135110870/home?from=page_100606&mod=TAB&is_all=1#place'
    },
    {
      type: require('@/assets/icon/platform/weixin.svg'),
      url: '#javascript:;'
    },
    {
      type: require('@/assets/icon/platform/zhihu.svg'),
      url: '#javascript:;'
    },
    // {
    //   type: require('@/assets/icon/platform/toutiao.svg'),
    //   url: 'https://www.toutiao.com/c/user/token/MS4wLjABAAAAycFiujkE1i80-Yq97W5wGgi95uB8IkbS-lTPirqjzOw/'
    // },
    // {
    //   type: require('@/assets/icon/platform/souhu.svg'),
    //   url: 'https://mp.sohu.com/profile?xpt=cHBhZzE4MzQ0MDIxNjA0Y0Bzb2h1LmNvbQ=='
    // },
  ],
  footerList: [
    [
      {
        id: 1,
        topic: '简介',
        url: '/introduction'
      },
      {
        id: 2,
        topic: '联系我们',
        url: '/connection'
      },
      {
        id: 3,
        topic: '加入我们',
        url: '/recruit'
      },
    ],
    [
      {
        id: 4,
        topic: '社会招聘',
        url: '/recruit/society'
      },
      {
        id: 5,
        topic: '校园招聘',
        url: '/recruit/campus'
      },
      {
        id: 6,
        topic: '业务/产品',
        url: '/recruit/business'
      },
      {
        id: 7,
        topic: '木木西里官网',
        url: '/'
      },
    ]
  ]
}