import { AxiosInstance } from "axios"
export default (axios: AxiosInstance) => {
  return {
    queryAdvertisement: (data: { count?: number, name: string }, headers: any) => {
      return axios({
        url: `/apiApi/advertisement/info`,
        method: 'get',
        params: data,
        headers: {
          ...headers
        }
      })
    },
    // 首页最新动态-获取最近3篇文章
    queryArticleList: () => {
      return axios({
        url: `/misServiceApi/app/website/article/list`,
        method: 'get',
      })
    },
    // 首页最新动态-获取文章详情
    queryArticleDetail: (data: { tokenArticleId: string | number }) => {
      return axios({
        url: `/misServiceApi/app/website/article/detail`,
        method: 'get',
        params: data,
      })
    },
  }
}
