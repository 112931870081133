
import footer from '@/db/footer'
import { Component, Prop, Vue } from 'nuxt-property-decorator'
@Component
export default class extends Vue {
  @Prop({ default: false }) isFromRecruit!: boolean
  followList: Array<any> = footer.followList
  footerList: Array<any> = footer.footerList
  currentYear: number = new Date().getUTCFullYear()
}
