export default function ({ route, redirect  }: any) {
  let routes = [
    '/',
    '/introduction',
    '/business',
    '/product/internet',
    '/product/hardware',
    '/product/voice',
    '/employee',
    '/connection',
    '/connection/dynamics',
    '/connection/cooperation',
    '/recruit',
    '/recruit/society',
    '/recruit/campus',
    '/recruit/campus/future',
    '/recruit/business',
    '/recruit/employee',
  ]
  if(routes.findIndex((e: string)=> e == route.path) == -1) {
    return redirect('/')
  }
  if (route.path === '/recruit/campus') {
    return redirect('https://app.mokahr.com/campus-recruitment/mumuxili/94826')
  }
}
