var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:_vm.$store.state.isPullDown === 1
        ? 'header-state down-active'
        : _vm.$store.state.isPullDown === -1
        ? 'header-state up-active'
        : _vm.isFromRecruit
        ? 'header-state header-bg-recruit'
        : _vm.isFromIndex
        ? 'header-state header-bg-index'
        : 'header-state'},[_c('div',{staticClass:"page-header"},[_c('div',{staticClass:"header-container"},[_c('div',{staticClass:"logo"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{attrs:{"src":_vm.$route.path === '/recruit/employee' ? _vm.headerLogo[0] : _vm.showState ? _vm.headerLogo[1] : _vm.headerLogo[0],"alt":""}})]),_vm._v(" "),(_vm.isFromRecruit)?_c('div',[_vm._v("招聘")]):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"menu-box",on:{"click":function($event){$event.stopPropagation();_vm.menuVisible = true}}},[_c('img',{attrs:{"src":_vm.showState ? _vm.headerMenu[1] : _vm.headerMenu[0],"alt":""}})])])]),_vm._v(" "),_c('header-menu',{attrs:{"visible":_vm.menuVisible,"topSide":_vm.isFromRecruit,"list":_vm.isFromRecruit ? _vm.recruitList : _vm.navList},on:{"tap":_vm.handleMenuTap,"close":function($event){_vm.menuVisible = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }