export const BusinessBrief = () => import('../../components/Business/Brief.vue' /* webpackChunkName: "components/business-brief" */).then(c => wrapFunctional(c.default || c))
export const BusinessDevice = () => import('../../components/Business/Device.vue' /* webpackChunkName: "components/business-device" */).then(c => wrapFunctional(c.default || c))
export const BusinessFuture = () => import('../../components/Business/Future.vue' /* webpackChunkName: "components/business-future" */).then(c => wrapFunctional(c.default || c))
export const BusinessSaas = () => import('../../components/Business/Saas.vue' /* webpackChunkName: "components/business-saas" */).then(c => wrapFunctional(c.default || c))
export const BusinessSupply = () => import('../../components/Business/Supply.vue' /* webpackChunkName: "components/business-supply" */).then(c => wrapFunctional(c.default || c))
export const BusinessUser = () => import('../../components/Business/User.vue' /* webpackChunkName: "components/business-user" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenu = () => import('../../components/HeaderMenu/index.vue' /* webpackChunkName: "components/header-menu" */).then(c => wrapFunctional(c.default || c))
export const HomeCooperation = () => import('../../components/Home/Cooperation.vue' /* webpackChunkName: "components/home-cooperation" */).then(c => wrapFunctional(c.default || c))
export const HomeDisplay = () => import('../../components/Home/Display.vue' /* webpackChunkName: "components/home-display" */).then(c => wrapFunctional(c.default || c))
export const HomeMarket = () => import('../../components/Home/Market.vue' /* webpackChunkName: "components/home-market" */).then(c => wrapFunctional(c.default || c))
export const HomeService = () => import('../../components/Home/Service.vue' /* webpackChunkName: "components/home-service" */).then(c => wrapFunctional(c.default || c))
export const HomeTrends = () => import('../../components/Home/Trends.vue' /* webpackChunkName: "components/home-trends" */).then(c => wrapFunctional(c.default || c))
export const IntroductionBrief = () => import('../../components/Introduction/Brief.vue' /* webpackChunkName: "components/introduction-brief" */).then(c => wrapFunctional(c.default || c))
export const IntroductionCulture = () => import('../../components/Introduction/Culture.vue' /* webpackChunkName: "components/introduction-culture" */).then(c => wrapFunctional(c.default || c))
export const IntroductionFramework = () => import('../../components/Introduction/Framework.vue' /* webpackChunkName: "components/introduction-framework" */).then(c => wrapFunctional(c.default || c))
export const IntroductionIdea = () => import('../../components/Introduction/Idea.vue' /* webpackChunkName: "components/introduction-idea" */).then(c => wrapFunctional(c.default || c))
export const IntroductionLocation = () => import('../../components/Introduction/Location.vue' /* webpackChunkName: "components/introduction-location" */).then(c => wrapFunctional(c.default || c))
export const IntroductionTime = () => import('../../components/Introduction/Time.vue' /* webpackChunkName: "components/introduction-time" */).then(c => wrapFunctional(c.default || c))
export const EmployeeActivities = () => import('../../components/Employee/Activities.vue' /* webpackChunkName: "components/employee-activities" */).then(c => wrapFunctional(c.default || c))
export const EmployeeCard = () => import('../../components/Employee/EmployeeCard.vue' /* webpackChunkName: "components/employee-card" */).then(c => wrapFunctional(c.default || c))
export const EmployeeRecruitment = () => import('../../components/Employee/Recruitment.vue' /* webpackChunkName: "components/employee-recruitment" */).then(c => wrapFunctional(c.default || c))
export const EmployeeTalentDevelopment = () => import('../../components/Employee/TalentDevelopment.vue' /* webpackChunkName: "components/employee-talent-development" */).then(c => wrapFunctional(c.default || c))
export const MumuEntry = () => import('../../components/Mumu/Entry.vue' /* webpackChunkName: "components/mumu-entry" */).then(c => wrapFunctional(c.default || c))
export const MumuProgress = () => import('../../components/Mumu/Progress.vue' /* webpackChunkName: "components/mumu-progress" */).then(c => wrapFunctional(c.default || c))
export const MumuSlide = () => import('../../components/Mumu/Slide.vue' /* webpackChunkName: "components/mumu-slide" */).then(c => wrapFunctional(c.default || c))
export const MumuTab = () => import('../../components/Mumu/Tab.vue' /* webpackChunkName: "components/mumu-tab" */).then(c => wrapFunctional(c.default || c))
export const MumuTitle = () => import('../../components/Mumu/Title.vue' /* webpackChunkName: "components/mumu-title" */).then(c => wrapFunctional(c.default || c))
export const MumuTitleBox = () => import('../../components/Mumu/TitleBox.vue' /* webpackChunkName: "components/mumu-title-box" */).then(c => wrapFunctional(c.default || c))
export const MumuTree = () => import('../../components/Mumu/Tree.vue' /* webpackChunkName: "components/mumu-tree" */).then(c => wrapFunctional(c.default || c))
export const RecruitBanner = () => import('../../components/Recruit/Banner.vue' /* webpackChunkName: "components/recruit-banner" */).then(c => wrapFunctional(c.default || c))
export const RecruitEmployeeStory = () => import('../../components/Recruit/EmployeeStory.vue' /* webpackChunkName: "components/recruit-employee-story" */).then(c => wrapFunctional(c.default || c))
export const RecruitOnRecruiting = () => import('../../components/Recruit/OnRecruiting.vue' /* webpackChunkName: "components/recruit-on-recruiting" */).then(c => wrapFunctional(c.default || c))
export const RecruitOnRecruitingIndex = () => import('../../components/Recruit/OnRecruitingIndex.vue' /* webpackChunkName: "components/recruit-on-recruiting-index" */).then(c => wrapFunctional(c.default || c))
export const RecruitQuestionAnswer = () => import('../../components/Recruit/QuestionAnswer.vue' /* webpackChunkName: "components/recruit-question-answer" */).then(c => wrapFunctional(c.default || c))
export const BusinessCompPdline = () => import('../../components/Business/Comp/Pdline.vue' /* webpackChunkName: "components/business-comp-pdline" */).then(c => wrapFunctional(c.default || c))
export const BusinessCompPresentation = () => import('../../components/Business/Comp/Presentation.vue' /* webpackChunkName: "components/business-comp-presentation" */).then(c => wrapFunctional(c.default || c))
export const BusinessCompService = () => import('../../components/Business/Comp/Service.vue' /* webpackChunkName: "components/business-comp-service" */).then(c => wrapFunctional(c.default || c))
export const ConnectionCooperationAgent = () => import('../../components/Connection/Cooperation/Agent.vue' /* webpackChunkName: "components/connection-cooperation-agent" */).then(c => wrapFunctional(c.default || c))
export const ConnectionCooperationBanner = () => import('../../components/Connection/Cooperation/Banner.vue' /* webpackChunkName: "components/connection-cooperation-banner" */).then(c => wrapFunctional(c.default || c))
export const ConnectionCooperationPlatform = () => import('../../components/Connection/Cooperation/Platform.vue' /* webpackChunkName: "components/connection-cooperation-platform" */).then(c => wrapFunctional(c.default || c))
export const ConnectionCooperationQualification = () => import('../../components/Connection/Cooperation/Qualification.vue' /* webpackChunkName: "components/connection-cooperation-qualification" */).then(c => wrapFunctional(c.default || c))
export const ConnectionCooperationTechnology = () => import('../../components/Connection/Cooperation/Technology.vue' /* webpackChunkName: "components/connection-cooperation-technology" */).then(c => wrapFunctional(c.default || c))
export const ConnectionHomeContact = () => import('../../components/Connection/Home/Contact.vue' /* webpackChunkName: "components/connection-home-contact" */).then(c => wrapFunctional(c.default || c))
export const ConnectionHomeCooperation = () => import('../../components/Connection/Home/Cooperation.vue' /* webpackChunkName: "components/connection-home-cooperation" */).then(c => wrapFunctional(c.default || c))
export const ConnectionHomeDynamics = () => import('../../components/Connection/Home/Dynamics.vue' /* webpackChunkName: "components/connection-home-dynamics" */).then(c => wrapFunctional(c.default || c))
export const ProductHardwareBio = () => import('../../components/Product/Hardware/Bio.vue' /* webpackChunkName: "components/product-hardware-bio" */).then(c => wrapFunctional(c.default || c))
export const ProductHardwareGeneral = () => import('../../components/Product/Hardware/General.vue' /* webpackChunkName: "components/product-hardware-general" */).then(c => wrapFunctional(c.default || c))
export const ProductHardwareHard = () => import('../../components/Product/Hardware/Hard.vue' /* webpackChunkName: "components/product-hardware-hard" */).then(c => wrapFunctional(c.default || c))
export const ProductHardwareKm = () => import('../../components/Product/Hardware/Km.vue' /* webpackChunkName: "components/product-hardware-km" */).then(c => wrapFunctional(c.default || c))
export const ProductInternetBar = () => import('../../components/Product/Internet/Bar.vue' /* webpackChunkName: "components/product-internet-bar" */).then(c => wrapFunctional(c.default || c))
export const ProductInternetMall = () => import('../../components/Product/Internet/Mall.vue' /* webpackChunkName: "components/product-internet-mall" */).then(c => wrapFunctional(c.default || c))
export const ProductInternetSaas = () => import('../../components/Product/Internet/Saas.vue' /* webpackChunkName: "components/product-internet-saas" */).then(c => wrapFunctional(c.default || c))
export const ProductInternetYqj = () => import('../../components/Product/Internet/Yqj.vue' /* webpackChunkName: "components/product-internet-yqj" */).then(c => wrapFunctional(c.default || c))
export const ProductInternetYqmm = () => import('../../components/Product/Internet/Yqmm.vue' /* webpackChunkName: "components/product-internet-yqmm" */).then(c => wrapFunctional(c.default || c))
export const ProductInternetZhimyan = () => import('../../components/Product/Internet/Zhimyan.vue' /* webpackChunkName: "components/product-internet-zhimyan" */).then(c => wrapFunctional(c.default || c))
export const RecruitCompJobCard = () => import('../../components/Recruit/Comp/JobCard.vue' /* webpackChunkName: "components/recruit-comp-job-card" */).then(c => wrapFunctional(c.default || c))
export const RecruitProfessionData = () => import('../../components/Recruit/Profession/Data.vue' /* webpackChunkName: "components/recruit-profession-data" */).then(c => wrapFunctional(c.default || c))
export const RecruitProfessionEquipment = () => import('../../components/Recruit/Profession/Equipment.vue' /* webpackChunkName: "components/recruit-profession-equipment" */).then(c => wrapFunctional(c.default || c))
export const RecruitProfessionSpecialTitle = () => import('../../components/Recruit/Profession/SpecialTitle.vue' /* webpackChunkName: "components/recruit-profession-special-title" */).then(c => wrapFunctional(c.default || c))
export const ConnectionCooperationCompAdvantages = () => import('../../components/Connection/Cooperation/comp/Advantages.vue' /* webpackChunkName: "components/connection-cooperation-comp-advantages" */).then(c => wrapFunctional(c.default || c))
export const ConnectionCooperationCompContact = () => import('../../components/Connection/Cooperation/comp/Contact.vue' /* webpackChunkName: "components/connection-cooperation-comp-contact" */).then(c => wrapFunctional(c.default || c))
export const ConnectionCooperationCompTitle = () => import('../../components/Connection/Cooperation/comp/Title.vue' /* webpackChunkName: "components/connection-cooperation-comp-title" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
