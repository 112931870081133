
import headerData from '@/db/header'
import {Component, Prop, Vue, Watch} from 'nuxt-property-decorator'

@Component
export default class extends Vue {
  @Prop({default: false}) visible: boolean | undefined
  @Prop({default: false}) topSide?: boolean | undefined
  @Prop({default: () => []}) list: Array<any> | undefined

  headerLogo: Array<any> = headerData.headerLogo
  isShow: boolean = false

  @Watch('visible', {immediate: true}) handleVisible(val: boolean) {
    if (process.client) {
      this.$nextTick(() => {
        // 禁止页面滚动
        let body: any = document.querySelector('body')
        if (val) {
          body.style.width = '100vw'
          body.style.height = '100vh'
          body.style.overflow = 'hidden'

          // @ts-ignore
          this.$refs.headerMenu.style.display = 'block'
        } else {
          body.style.width = 'auto'
          body.style.height = 'auto'
          body.style.overflow = 'auto'

          // @ts-ignore
          this.$refs.headerMenu.style.display = 'none'
        }
      })
    }
  }

  handleClose() {
    this.$emit('close', false)
  }

  handleBackHome() {
    this.$router.push('/')
    this.$emit('close', false)
  }

  handleClick(value: any) {
    this.$emit('tap', value)
  }

  getH(len: number) {
    return len * (0.21 + 0.16) + 'rem'
  }
}
